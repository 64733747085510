<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h3 slot="header" class="card-title">Editar Franquia</h3>
      <FranchiseForm :typeForm="typeForm" ref="form" />
      <div slot="footer" class="col-12 d-flex justify-content-end flex-wrap">
        <div class="col-4">
          <button class="custom-btn-add m-1" @click="$router.push({ name: 'Franchises' })">Voltar</button>
          <button class="custom-btn-save m-1" @click="update">Atualizar</button>
        </div>
      </div>
      <UpdateLog :dataLog="logs" />
    </card>
  </transition>
</template>
<script>
import FranchiseForm from "../components/FranchiseForm.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import constants from "@/util/constants";
import { EventBus } from "@/eventBus";
import UpdateLog from "../../../components/UpdateLog.vue";

export default {
  components: {
    FranchiseForm,
    UpdateLog
  },
  data() {
    return {
      typeForm: constants.typeForm.edition,
    };
  },
  computed: {
    ...mapState(['ecommerce']),
    ...mapState('franchise', ['franchises', 'logs'])
  },
  methods: {
    ...mapActions("franchise", ["updateFranchise", "getFranchiseLog"]),
    ...mapMutations('franchise', ['SET_FRANCHISE_CURRENT']),
    async update() {
      let form = this.$refs.form;

      if (!form.validate())
        return;

      let payload = form.getValue();
      await this.updateFranchise(payload).then(() => {
        this.$showSuccess("Filial atualizada com sucesso");

        this.$router.push({ name: "Franchises" });
      }).catch(error => {
        if (error.response.data)
          this.$showError(error.response.data);
        else
          this.$showError(error);
      });
    },
    getFranchiseCurrent() {
      let franchiseId = this.$route.params.id;
      let franchise = this.franchises.find(a => a.Id === franchiseId);
      this.SET_FRANCHISE_CURRENT(franchise);
      this.getFranchiseLog(franchiseId);
      EventBus.$emit("loadFranchise");
    },
  },
  async mounted() {
    this.getFranchiseCurrent();
  },
};
</script>
<style>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #00ADB7;
  color: #ffffff !important;
}

.el-button-group>.el-button.is-active,
.el-button-group>.el-button:active,
.el-button-group>.el-button:focus,
.el-button-group>.el-button:hover {
  color: #ffffff !important;
  background-color: #00ADB7 !important;
}

.el-button {
  display: inline-block;
  color: #ffffff;
  background-color: #00ADB7;
}
</style>
