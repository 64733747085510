<template>
  <el-dialog title="Campos Customizados" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
    <base-input>
      <el-input type="textarea" v-model="fulfillmentCurrent.CustomPlatformFields" :disabled="isReadonlyData()"
        :autosize="{ minRows: 15, minColls: 50 }">
      </el-input>
    </base-input>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">Fechar</el-button>
      <el-button v-if="getUserPermission('00_c') && (fulfillmentCurrent.Status.BahnReference == 'error') && !isReadonlyData()"
        type="primary" @click="updateModal">Atualizar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Modal from '@/components/Modal';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    Modal
  },
  data() {
    return {
      dialogVisible: false,
      number: null,
      loading: false,
      ecommerceName: null,
    }
  }, computed: {
    ...mapState(['ecommerce']),
    ...mapState("health", ["displayRestrictedData"]),
    ...mapState("fulfillment", ["fulfillmentCurrent"]),
  },
  methods: {
    ...mapActions("orders", ["updateCustomFields", "logViewedCustomFields"]),

    handleClose() {
      this.$router.go(-1);
    },
    logCustomFieldsVisualization() {
      if (this.fulfillmentCurrent.Anonymized) return;
      this.logViewedCustomFields({
        ecommerceName: this.fulfillmentCurrent.EcommerceName,
        number: this.fulfillmentCurrent.Number
      }).catch((error) => this.$showError(error))
    },
    isReadonlyData() {
      return !this.displayRestrictedData || this.fulfillmentCurrent.Anonymized;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    updateModal() {
      let order = {
        UpdateReason: 'Atualização de campos customizados',
        CustomPlatformFields: this.fulfillmentCurrent.CustomPlatformFields,
        Id: this.fulfillmentCurrent.Id
      }
      this.fulfillmentCurrent.UpdateReason = 'Atualização de campos customizados';

      this.updateCustomFields(order).then(() => {
        this.$showSuccess("Campos customizados atualizado com sucesso.");
        this.$router.go(-1);
      }).catch((error) => this.$showError(error))
    },
  },
  async mounted() {
    this.dialogVisible = true;
    this.logCustomFieldsVisualization();
  },
  async destroyed() {
    this.dialogVisible = false;
  },
}
</script>
