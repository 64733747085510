<template>
  <div class="row">
    <div class="col-4">
      <card type="tasks" class="stacked-form">
          <div class="col-12">
            <base-input>
              <label>SKU ERP:</label>
              <el-tooltip content="Sku de referência no ERP." effect="light"
                :open-delay="100" placement="top">
                <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
              </el-tooltip>
              <el-input v-model="stockConfiguration.Sku" placeholder="Informe o sku" :disabled="disabledField" />
            </base-input>
          </div>
          <div class="col-12">
            <base-input>
              <label>Estoque de segurança inteligente:</label>
              <el-tooltip content="A quantidade informada será deduzida do saldo disponível do SAP" effect="light"
                :open-delay="100" placement="top">
                <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
              </el-tooltip>
              <el-input v-model="stockConfiguration.IntelligentSafetyQuantity"
                placeholder="Informe a quantidade de Estoque de segurança inteligente" type="number" />
            </base-input>
          </div>
          <div class="col-12">
            <base-input>
              <label>Estoque de segurança básico:</label>
              <el-tooltip content="O saldo de estoque será zerado quando atingir a quantidade informada" effect="light"
                :open-delay="100" placement="top">
                <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
              </el-tooltip>
              <el-input v-model="stockConfiguration.BasicSafetyQuantity"
                placeholder="Informe a quantidade de Estoque de segurança básico" type="number" />
            </base-input>
          </div>
          <div class="col-12">
            <base-input>
              <label>Estoque máximo:</label>
              <el-tooltip content="A quantidade informada será o saldo máximo a ser enviado ao ecommerce" effect="light"
                :open-delay="100" placement="top">
                <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
              </el-tooltip>
              <el-input v-model="stockConfiguration.MaximumQuantity" placeholder="Informe a quantidade de Estoque máximo"
                type="number" />
            </base-input>
          </div>
          <div class="col-12">
            <base-input>
              <label>Status:</label><br />
              <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="stockConfiguration.Active" :sync="true" />
            </base-input>
          </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { EventBus } from "@/eventBus";

export default {
  computed: {
    ...mapState('stockConfiguration', ['stockConfigurationCurrent']),
    ...mapState(['ecommerce']),
  },
  data() {
    return {
      stockConfiguration: {
        EcommerceName: "",
        Sku: "",
        IntelligentSafetyQuantity: "",
        BasicSafetyQuantity: "",
        MaximumQuantity: "",
        Active: true
      },
      disabledField: false
    }
  },

  methods: {
    save() {
      this.$emit("save");
    },
    validate() {
      if (this.$isNullOrEmpty(this.stockConfiguration.Sku)) {
        this.$showError("Sku obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.stockConfiguration.IntelligentSafetyQuantity)) {
        this.$showError("Quantidade de segurança inteligente obrigatória.");
        return false;
      } else if (!this.$isNullOrEmpty(this.stockConfiguration.BasicSafetyQuantity) && !this.$isNullOrEmpty(this.stockConfiguration.MaximumQuantity)) {
        this.$showError("Estoque de segurança básico e Estoque máximo não podem ser preenchidos ao mesmo tempo.");
        return false;
      } else
        return true;
    },
    getValue() {
      return this.stockConfiguration;
    },
    load() {
      this.stockConfiguration = this.stockConfigurationCurrent;
      if (this.stockConfigurationCurrent) {
        this.disabledField = true;
      }
    }
  },
  mounted() {
    EventBus.$on("loadStockConfiguration", () => this.load());
  },
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
