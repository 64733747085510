<template>
  <div class="row">
    <div class="col-6">
      <card type="tasks" class="stacked-form">
        <div class="col-6">
          <div>
            <base-input>
              <label>Nome:</label>
              <el-input v-model="franchise.Name" placeholder="Informe o nome" :disabled="disabledField" />
            </base-input>
          </div>
          <div>
            <base-input>
              <label>Id da Loja:</label>
              <el-input :disabled="disabledField" v-model="franchise.StoreId" placeholder="Informe Id da Loja" />
            </base-input>
          </div>
          <div>
            <base-input>
              <label>Id do vendedor:</label>
              <el-input v-model="franchise.SellerId" placeholder="Informe o Id do vendedor" />
            </base-input>
          </div>
          <div>
            <base-input>
              <label>Id do Depósito:</label>
              <el-input v-model="franchise.WarehouseCode" placeholder="Informe o Id do depósito" />
            </base-input>
          </div>
          <div>
            <base-input>
              <label>CNPJ:</label>
              <el-input v-model="franchise.Document" placeholder="Informe o CNPJ" />
            </base-input>
          </div>
          <div>
            <base-input>
              <label>Token da Api de Estoque:</label>
              <el-input v-model="franchise.StockAPIToken" placeholder="Informe o Token da API de Estoque" />
            </base-input>
          </div>
          <div>
            <base-input label="Tipo de Entrega">
              <el-select class="select-primary" v-model="franchise.DeliveryType" placeholder="Informe o Tipo de Entrega"
                filterable>
                <el-option v-for="item in deliveryTypes" :key="item.Value" :label="item.Label" :value="item.Value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div>
            <base-input label="Responsável pela entrega">
              <el-select class="select-primary" v-model="franchise.DeliveryManager"
                placeholder="Informe o Responsável pela entrega" filterable>
                <el-option v-for="item in deliverManagers" :key="item.Value" :label="item.Label" :value="item.Value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div>
            <base-input label="Depósito E-commerce">
              <el-select class="select-primary" v-model="franchise.WarehouseConfigurationId"
                placeholder="Informe o Depósito E-commerce" filterable>
                <el-option v-for="item in warehouseSelectField" :key="item.Value" :label="item.Label"
                  :value="item.Value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div>
            <base-input>
              <label>Status:</label><br />
              <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="franchise.Active" :sync="true" />
            </base-input>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { EventBus } from "@/eventBus";
import constants from '@/util/constants';

export default {
  computed: {
    ...mapState('franchise', ['franchiseCurrent']),
    ...mapState(['ecommerce']),
    ...mapGetters("franchise", ["warehouseSelectField"]),
  },
  data() {
    return {
      franchise: {
        EcommerceName: "",
        Name: "",
        StoreId: "",
        SellerId: "",
        WarehouseConfigurationId: "",
        DeliveryType: "",
        DeliveryManager: "",
        Active: true
      },
      disabledField: false,
      deliveryTypes: constants.DeliveryType,
      deliverManagers: constants.DeliveryManager,
    }
  },

  methods: {
    ...mapActions("franchise", ["getWarehouses"]),
    save() {
      this.$emit("save");
    },
    validate() {
      if (this.$isNullOrEmpty(this.franchise.Name)) {
        this.$showError("Nome obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.franchise.StoreId)) {
        this.$showError("Id da loja obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.franchise.SellerId)) {
        this.$showError("Id do vendedor obrigatório.");
        return false;
      } else
        return true;
    },
    getValue() {
      return this.franchise;
    },
    async load() {
      await this.getWarehouses();
      this.franchise = this.franchiseCurrent;
      if (this.franchiseCurrent) {
        this.disabledField = true;
      }
    }
  },
  mounted() {
    EventBus.$on("loadFranchise", async () => await this.load());
  },
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
