<template>
  <div>
    <div class="row">
      <div class="col-9">
        <card type="tasks" class="table-full-width">
          <div class="d-flex justify-content-between">
            <h4 slot="header" class="card-title">
              <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                <i class="material-icons">arrow_back</i>
              </base-button>
              Detalhe Campo Customizado
            </h4>
            <button @click="update" type="button" class="btn btn-success">
              <i class="fa fa-refresh" aria-hidden="true"></i>
              Atualizar
            </button>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3">
              <base-input label="Nome do Ecommerce *" :error="err.ecommerceName">
                <el-input :disabled="true" placeholder="Informar o nome do ecommerce"
                  v-model="form.ecommerceName"></el-input>
              </base-input>
            </div>
            <div class="col-12 col-sm-3">
              <base-input label="Campo no ERP *" :error="err.erpField">
                <el-input placeholder="Informar o campo no ERP" v-model="form.erpField"></el-input>
              </base-input>
            </div>
            <div class="col-12 col-sm-3">
              <base-input>
                <label>De - Para *
                  <el-tooltip
                    :content="form.typeRule === 0 ? 'Ecommerce para ERP é utilizado o objeto do Ecommerce para mapear o campo e integrar no ERP.' : 'Bahn para ERP é utilizado o objeto do Bahn para mapear o campo e integrar no ERP.'"
                    effect="light" :open-delay="100" placement="top">
                    <i class="material-icons ml-1 mb-2 icon-custom">info_outline</i>
                  </el-tooltip>
                </label>
                <el-select class="select-primary" v-model="form.typeRule">
                  <el-option v-for="option in customFieldOrigin" :value="option.Key" :label="option.Label"
                    :key="option.Key"></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-12 col-sm-3">
              <base-input :label="form.typeRule === 0 ? 'Campo no Ecommerce' : 'Campo no Bahn'">
                <el-input placeholder="Informar o campo no ecommerce" v-model="form.platformField"></el-input>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <base-input label="Tipo de Campo *" :error="err.fieldType">
                <el-select class="select-primary" v-model="form.fieldType">
                  <el-option v-for="option in fieldTypes" :value="option.Key" :label="option.Label"
                    :key="option.Key"></el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-12 col-sm-6">
              <base-input label="Tipo do Documento *" :error="err.documentType">
                <el-select class="select-primary" v-model="form.documentType">
                  <el-option v-for="option in documentTypes" :value="option.Key" :label="option.Label"
                    :key="option.Key"></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <base-input label="Regra *" :error="err.rule">
                <el-select class="select-primary" v-model="form.rule">
                  <el-option v-for="option in rules" :value="option.Key" :label="option.Label"
                    :key="option.Key"></el-option>
                </el-select>
              </base-input>
            </div>
            <div v-if="form.rule === 6" class="col-12 col-sm-6">
              <base-input label="Valor Padrão" :error="err.defaultValue">
                <el-select class="select-primary" v-model="form.defaultValue">
                  <el-option v-for="item in formatsDate" :key="item.name" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </base-input>
            </div>
            <div v-else class="col-12 col-sm-6">
              <base-input label="Valor Padrão" :error="err.defaultValue">
                <el-input placeholder="Valor padrão do campo" v-model="form.defaultValue"></el-input>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <base-input>
                <label>Texto Pré-definido:</label>
                <el-input type="textarea" placeholder="Informar o texto pré-definido" v-model="form.predefinedText"
                  :disabled="form.fieldType === 0 ? false : true">
                </el-input>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <base-input label="Não criar lista em caso de nulo">
              <toggle-button
                :labels="{ checked: 'ON', unchecked: 'OFF' }"
                color="#7dd7dc"
                input
                type="checkbox"
                v-model="form.dontCreateFieldOnNullList"
                :sync="true"
              />
              </base-input>
            </div>
          </div>
          <div class="row" v-if="form.rule === 7">
            <div class="col-12 col-sm-6">
              <base-input label="Campo para Comparar no ERP" :error="err.rule">
              <el-input type="textarea" placeholder="Informar o campo" v-model="form.propertyCompareERP">
                  </el-input>
              </base-input>
            </div>
            <div class="col-12 col-sm-6">
              <base-input label="Campo para Comparar no Ecommerce ERP" :error="err.defaultValue">
              <el-input type="textarea" placeholder="Informar o campo" v-model="form.propertyComparePlataform">
                </el-input>
              </base-input>
            </div>
          </div>
        </card>
      </div>
      <div class="col-3">
        <card type="tasks" class="stacked-form">
          <h4 class="card-title">Legenda</h4>
          <div class="col-12">
            <el-table :data="tagsData">
              <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                :prop="column.prop" :label="column.label" align="center"></el-table-column>
            </el-table>
          </div>
          <br /><br /> <br /><br /> <br /><br /> <br /><br /> <br />
          <h5><strong>* Utilize a TAG para personalizar o texto pré-definido para campos do tipo STRING</strong></h5>
        </card>
      </div>
    </div>
    <card class="table-full-width">
      <CustomFieldConditions ref="conditions" :conditions="form.customFieldCondition" />
    </card>
    <card class="table-full-width">
      <UpdateLog :dataLog="dataLog" />
    </card>
  </div>
</template>

<script>
import constants from '@/util/constants'
import CustomFieldConditions from "../components/CustomFieldConditions";
import { mapActions, mapState } from 'vuex';
import UpdateLog from "../../../components/UpdateLog.vue"
export default {
  name: "CustomFieldDetail",
  components: {
    CustomFieldConditions,
    UpdateLog
  },
  data() {
    return {
      dataLog: [],
      form: {
        id: null,
        ecommerceName: "",
        fieldType: null,
        documentType: null,
        rule: null,
        platformField: null,
        erpField: null,
        defaultValue: null,
        predefinedText: null,
        customFieldCondition: [],
        propertyCompareERP: null,
        propertyComparePlataform: null,
        dontCreateFieldOnNullList: null,
        active: true,
        typeRule: null,
        name: null,
      },
      err: {
        fieldType: null,
        documentType: null,
        rule: null,
        erpField: null,
        defaultValue: null,
        propertyComparePlataform: null,
        propertyCompareERP: null
      },
      fieldTypes: [],
      documentTypes: [],
      rules: [],
      tableColumns: [
        {
          prop: "Tag",
          label: "Tag",
          minWidth: 100,
        },
        {
          prop: "Description",
          label: "Descrição",
          minWidth: 100,
        }
      ],
      tagsData: [
        {
          Tag: '@PlatformField',
          Description: 'Campo no E-commerce'
        },
        {
          Tag: '@DefaultValue',
          Description: 'Valor Padrão'
        }
      ],
      formatsDate: [
        {
          name: "dd/MM/yyyy",
          value: "dd/MM/yyyy"
        },
        {
          name: "dd/MM/yyyy HH:mm:ss",
          value: "dd/MM/yyyy HH:mm:ss"
        },
      ],
      customFieldOrigin: []
    }
  },
  computed: {
    ...mapState('customfield', ['customFields'])
  },
  methods: {
    ...mapActions('customfield', ['updateCustomField', 'getCustomField']),
    loadCustomFieldInformation() {
      this.fieldTypes = constants.CustomFieldType;
      this.documentTypes = constants.CustomFieldDocumentType;
      this.rules = constants.CustomFieldRule;
      this.customFieldOrigin = constants.CustomFieldOrigin;
    },
    async update() {
      try {
        if (!this.validate())
          return;
        let conditions = this.$refs.conditions.value();
        this.form.customFieldCondition = conditions;
        await this.updateCustomField(this.form);
        this.$showSuccess("O cadastro foi atualizado com sucesso");
        this.$router.push({ name: 'CustomField' });
      } catch (error) {
        this.$showError("Ocorreu um erro ao atualizar o cadastro");
      }
    },
    async setCustomFieldCurrent() {
      let customFieldId = this.$route.query.id;
      let customField = await this.getCustomField(customFieldId);
      if (customField) {
        this.form.id = customField.Id;
        this.form.ecommerceName = customField.EcommerceName;
        this.form.fieldType = customField.FieldType;
        this.form.documentType = customField.DocumentType;
        this.form.rule = customField.Rule;
        this.form.platformField = customField.PlatformField;
        this.form.erpField = customField.ErpField;
        this.form.defaultValue = customField.DefaultValue;
        this.form.predefinedText = customField.PredefinedText;
        this.form.customFieldCondition = customField.CustomFieldCondition;
        this.form.propertyCompareERP = customField.PropertyCompareERP;
        this.form.propertyComparePlataform = customField.PropertyComparePlataform;
        this.form.dontCreateFieldOnNullList = customField.DontCreateFieldOnNullList;
        this.form.active = customField.Active;
        this.form.typeRule = customField.TypeRule;
        this.form.name = customField.Name;
      }
      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        customFieldId +
        "&entity=CustomField"
      );

      this.dataLog = await responseLog.data;
    },
    validate() {
      let valid = true;
      let error = "Campo obrigatório";

      if (this.form.fieldType === null) {
        this.err.fieldType = error;
        valid = false;
      }
      if (this.form.documentType === null) {
        this.err.documentType = error;
        valid = false;
      }
      if (this.form.rule === null) {
        this.err.rule = error;
        valid = false;
      }
      if (this.$isNullOrEmpty(this.form.erpField)) {
        this.err.erpField = error;
        valid = false;
      }
      if (this.$isNullOrEmpty(this.form.platformField) && this.$isNullOrEmpty(this.form.defaultValue)) {
        this.err.defaultValue = error;
        valid = false;
      }
      if (this.form.rule === 6 && this.$isNullOrEmpty(this.form.defaultValue)) {
        this.errorsCustomFields.defaultValue = error;
        valid = false;
      }
      if (this.form.rule === 7 && this.$isNullOrEmpty(this.form.propertyCompareERP)) {
        this.errorsCustomFields.PropertyCompareERP = error;
        valid = false;
      }
      if (this.form.rule === 7 && this.$isNullOrEmpty(this.form.propertyComparePlataform)) {
        this.errorsCustomFields.PropertyComparePlataform = error;
        valid = false;
      }

      return valid;
    }
  },
  async mounted() {
    this.loadCustomFieldInformation();
    await this.setCustomFieldCurrent();
  }
}
</script>
<style >
textarea {
  background: transparent !important;
  border-color: #2b3553 !important;
}

.el-textarea__inner:focus {
  outline: 0;
  border-color: #00ADB7 !important;
}

.el-textarea__inner:hover {
  border-color: #00ADB7 !important;
}

.white-content .el-textarea.is-disabled .el-textarea__inner {
  background: #E3E3E3 !important;
  border-color: #E4E7ED;
  color: #C0C4CC;
  cursor: not-allowed;
}
</style>
