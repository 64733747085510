<template>
     <el-dialog title="Parceiro de Negócios Cadastrados" width="30%" :show-close="false" :close-on-click-modal="false"
      :visible.sync="showModal">
      <div>
              <!-- Form for new record -->
              <div v-if="showForm" class="mb-4 p-3 border rounded">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>CardCode</label>
                      <input v-model="newRecord.cardCode" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Documento</label>
                      <input v-model="newRecord.document" v-mask="computedMask" type="text" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button class="btn btn-primary" @click="addRecord">Adicionar</button>
                  <button class="btn btn-secondary ml-2" @click="showForm = false">Cancelar</button>
                </div>
              </div>

              <!-- Filter and Add button -->
              <div class="d-flex justify-content-end mb-3">
                <div class="d-flex">
                  <input
                    v-model="filters.document"
                    class="form-control mt-1 mr-2"
                    placeholder="Documento..."
                  >
                  <input
                    v-model="filters.cardCode"
                    class="form-control mt-1 mr-2"
                    placeholder="CardCode..."
                  >
                </div>
                <button class="btn btn-info mr-2" @click="loadFulfillmentConfigurationsBP">
                  <i class="tim-icons icon-search"></i> Filtrar
                </button>
                <button class="btn btn-primary" @click="showForm = true">
                  <i class="tim-icons icon-simple-add"></i> Novo Registro
                </button>
              </div>

              <el-table
                v-loading="loading"
                :data="fulfillmentConfigurationsBP.Items"
                element-loading-background="rgba(0, 0, 0, 0.0)"
                max-height="370px">
                <el-table-column
                  prop="Document"
                  label="Documento"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="CardCode"
                  label="CardCode"
                  width="120">
                </el-table-column>
                <el-table-column align="center" label="Ações">
                  <div slot-scope="props">
                    <base-button class="edit btn-link" type="danger" size="sm" icon @click="remove(props.row.Id)">
                      <i class="material-icons">delete_outline</i>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
              <div slot="footer" class="col-12 d-flex justify-content-center justify-content-between flex-wrap">
                <div class="col-4"></div>
                <div class="col-4">
                  <label>
                    Exibindo
                    <span class="primary-text">{{ from + 1 }}</span> -
                    <span class="primary-text">{{ to }}</span> de
                    <span class="primary-text">{{ total }}</span> registros
                  </label>
                </div>
                <div class="col-2">
                  <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
                    :total="total" @input="loadFulfillmentConfigurationsBP"></base-pagination>
                </div>
                <div class="col-2">
                  <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
                    v-model="pagination.perPage" placeholder="Per page">
                    <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                      :value="item"></el-option>
                  </el-select>
                </div>
              </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal">Fechar</el-button>
      </span>
    </el-dialog>
  </template>
  <script>
  import Modal from '@/components/Modal';
  import { BasePagination, BaseRadio } from "src/components";
  import { mapActions, mapState } from 'vuex';

  export default {
    components: {
      Modal,
      BaseRadio,
      BasePagination
    },
    data() {
      return {
        filters: {
          document: '',
          cardCode: '',
        },
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        show: false,
        loading: false,
        showForm: false,
        showModal: false,
        newRecord: {
          cardCode: '',
          document: '',
        }
      }
    }, computed: {
      ...mapState('fulfillment', ['fulfillmentConfigurationsBP']),
      computedMask() {
        return this.newRecord.document.length > 14 ? '##.###.###/####-##' : '###.###.###-##';
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.fulfillmentConfigurationsBP.Total;
      },
    },
    methods: {
      ...mapActions('fulfillment', ['getFulfillmentConfigurationsBP', 'removeFulfillmentConfigurationBP', 'createFulfillmentConfigurationBP', 'removeFulfillmentConfigurationBP']),
      async loadFulfillmentConfigurationsBP() {
        this.loading = true;
        let params = Object.assign(this.filters, this.pagination);
        this.getFulfillmentConfigurationsBP(params).then(() => {
          this.loading = false;
        }).catch(error => {
          this.loading = false;
          this.$showError(error);
        });
      },
      removeCharacterMask(value) {
        return value.replace(/[^\d]/g, '');
      },
      async remove(id) {
        this.loading = true;
        await this
          .removeFulfillmentConfigurationBP(id)
          .then(() => { 
            this.loading = false;
            this.$showSuccess("O cadastro foi excluido com sucesso") 
            this.loadFulfillmentConfigurationsBP();
          })
          .catch(error => {
            this.loading = false;
            this.$showError(error);
          });
      },
      closeModal() {
        this.showModal = false;
        this.$router.push({ path: '/configuration/FulfillmentConfiguration' });
      },
      addRecord() {
        this.loading = true;
        this.newRecord.document = this.removeCharacterMask(this.newRecord.document);
        this.createFulfillmentConfigurationBP(this.newRecord)
        .then(() => {
          this.$showSuccess("O cadastro foi inserido com sucesso");
          this.newRecord = {
            cardCode: '',
            document: '',
          };
          this.loadFulfillmentConfigurationsBP();
          this.showForm = false;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$showError(error);
        });
      }
    },
    mounted() {
      this.loadFulfillmentConfigurationsBP();
      this.showModal = true;
    }
  }
  </script>
  <style>
  </style>
